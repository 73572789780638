import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledInput {
  restrictWidth: any;
}

export const StyledInput = styled.label<IStyledInput>`
  display: flex;
  width: 100%;
  flex: 1 0 100%;
  max-width: ${({ restrictWidth }) => restrictWidth};
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1px;
  height: ${({ theme }) => theme.rhythmSizing(3.5)}rem;
  border-radius: ${rem(`8px`)};
  background: linear-gradient(
    -90deg,
    ${({ theme }) => theme.colors.blue_1},
    ${({ theme }) => theme.colors.pink_2}
  );
  background-size: 100% 100%;
  :focus-within {
    animation: Gradient 5s ease normal;
  }
  input {
    flex: 0 auto;
    padding: 0 8px 0 31px;
    width: 100%;
    height: ${rem(`40px`)};
    border: 1px solid transparent;
    border-radius: inherit;
    outline-style: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.blue_1};
    color: ${({ theme }) => theme.colors.white_1_60};
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray_3_30};
    }
  }
  .input__icon {
    position: absolute;
    z-index: 20;
    left: 9px;
    margin-top: 1px;
    color: ${({ theme }) => theme.colors.pink_2};
  }

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
