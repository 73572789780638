import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledInput } from './styles';

interface IInput {
  changeFn?(e: React.ChangeEvent<HTMLInputElement>): void;
  type: string;
  icon: IconProp;
  placeholder: string;
  restrictWidth?: string;
  className?: string;
  refTo?: any;
  name?: string;
  required?: boolean;
  minlength?: number;
  maxlength?: number;
  disabled?: boolean;
}

export const Input = ({
  className,
  changeFn,
  type = `text`,
  icon,
  placeholder = `Type here...`,
  restrictWidth = `100%`,
  refTo,
  name,
  required,
  minlength,
  maxlength,
  disabled = false,
}: IInput) => {
  const uuidNum = uuidv4();
  return (
    <StyledInput
      aria-label={name}
      htmlFor={`${name}_${uuidNum}`}
      className={className}
      restrictWidth={restrictWidth}
    >
      {icon ? <FontAwesomeIcon className="input__icon" icon={icon} /> : null}
      <input
        name={name}
        id={`${name}_${uuidNum}`}
        ref={refTo}
        type={type}
        placeholder={placeholder}
        onChange={changeFn}
        required={!!required}
        minLength={minlength || 0}
        maxLength={maxlength || 50}
        disabled={disabled}
      />
    </StyledInput>
  );
};
